import { all, call, fork, put, takeLeading } from 'redux-saga/effects'
import * as types from './types'
import * as apis from './apis'
import { setTransaction_action, setInvoices_action } from './actions'

function* getTransactionsSaga({ filters, resolve, reject }) {
  try {
    const response = yield call(apis.getTransactions_api, filters)
    if (!filters.is_export) {
      if (filters.is_invoice) {
        yield put(setInvoices_action(response))
      } else {
        yield put(setTransaction_action(response))
      }
    }
    resolve(response)
  } catch (error) {
    reject(error)
  }
}
function* createTransactionsSaga({ data, resolve, reject }) {
  try {
    const response = yield call(apis.createTransactions_api, data)
    resolve(response)
  } catch (error) {
    reject(error)
  }
}

function* deleteTransactionsSaga({ unique_id, resolve, reject }) {
  try {
    yield call(apis.deleteTransactions_api, unique_id)
    resolve()
  } catch (error) {
    reject(error)
  }
}

export function* watchSagas() {
  yield takeLeading(types.GET_TRANSACTIONS, getTransactionsSaga)
  yield takeLeading(types.CREATE_TRANSACTION, createTransactionsSaga)
  yield takeLeading(types.DELETE_TRANSACTION, deleteTransactionsSaga)
}
export default function* transactionsSagas() {
  yield all([fork(watchSagas)])
}
