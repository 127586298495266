import { isEmpty } from 'lodash'
import apiService from 'utils/apiService'
import { convertObjectToQuerystring } from 'utils/helper'
import { getUserToken } from 'utils/storageUtils'

export function getProducts_api(filters) {
  let token = getUserToken()
  let filter = ''
  if (!isEmpty(filters)) {
    filter = `&${convertObjectToQuerystring(filters)}`
  }
  return apiService(`products?${filter}`, null, 'get', token)
}
export function createProduct_api(data) {
  let token = getUserToken()
  return apiService(`products`, data, 'post', token)
}
export function updateProduct_api(data) {
  let token = getUserToken()
  data._method = 'put'
  return apiService(`products/${data.id}`, data, 'post', token)
}
export function deleteProduct_api(id) {
  let token = getUserToken()
  return apiService(`products/${id}`, null, 'delete', token)
}
