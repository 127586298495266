import { combineReducers } from 'redux'
import { connectRouter } from 'connected-react-router'
import authReducer from 'modules/auth/reducer'
import appReducer from 'modules/App/reducer'
import accountsReducer from 'modules/Accounts/reducer'
import transactionsReducer from 'modules/Transactions/reducer'
import gstReducer from 'modules/GST/reducer'
import categoryReducer from 'modules/Categories/reducer'
import productsReducer from 'modules/Products/reducer'

const rootReducer = (history) =>
  combineReducers({
    auth: authReducer,
    app: appReducer,
    accounts: accountsReducer,
    transactions: transactionsReducer,
    gstLog: gstReducer,
    categories: categoryReducer,
    products: productsReducer,
    router: connectRouter(history)
  })
export default rootReducer
