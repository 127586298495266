import { isEmpty } from 'lodash'
import apiService from 'utils/apiService'
import { convertObjectToQuerystring } from 'utils/helper'
import { getUserToken } from 'utils/storageUtils'

export function getGSTLogs_api(filters) {
  let token = getUserToken()
  let filter = ''
  if (!isEmpty(filters)) {
    filter = `&${convertObjectToQuerystring(filters)}`
  }
  return apiService(`gst?${filter}`, null, 'get', token)
}

export function udpateGSTLogs_api(data) {
  data._method = 'patch'
  const token = getUserToken()
  return apiService(`gst`, data, 'post', token)
}
