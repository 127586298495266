import { isEmpty } from 'lodash'
import apiService from 'utils/apiService'
import { convertObjectToQuerystring } from 'utils/helper'
import { getUserToken } from 'utils/storageUtils'

export function getAccounts_api(filters) {
  let token = getUserToken()
  let filter = ''
  if (!isEmpty(filters)) {
    filter = `&${convertObjectToQuerystring(filters)}`
  }
  return apiService(`accounts?${filter}`, null, 'get', token)
}
export function createAccounts_api(data) {
  let token = getUserToken()
  if (!data.id) {
    return apiService(`accounts`, data, 'post', token)
  } else {
    data._method = 'patch'
    return apiService(`accounts/${data.id}`, data, 'post', token)
  }
}
export function deleteAccounts_api(id) {
  let token = getUserToken()
  return apiService(`accounts/${id}`, null, 'delete', token)
}
export function getAccountDetail_api(id) {
  let token = getUserToken()
  return apiService(`accounts/${id}`, null, 'get', token)
}
