import * as types from './types'

export const getProducts_action = (filters, resolve, reject) => ({
  type: types.GET_PRODUCTS,
  filters,
  resolve,
  reject
})
export const setProducts_action = (data) => ({
  type: types.SET_PRODUCTS,
  data
})

export const createProduct_action = (data, resolve, reject) => ({
  type: types.CREATE_PRODUCT,
  data,
  resolve,
  reject
})
export const updateProduct_action = (data, resolve, reject) => ({
  type: types.UPDATE_PRODUCT,
  data,
  resolve,
  reject
})
export const deleteProduct_action = (id, resolve, reject) => ({
  type: types.DELETE_PRODUCT,
  id,
  resolve,
  reject
})
