import { useEffect, useRef } from 'react'
import { store } from 'store/store'

export const convertObjectToQuerystring = (obj) => {
  return Object.keys(obj)
    .map((key) => key + '=' + obj[key])
    .join('&')
}

export const isSpecialKeyCodes = [
  8, // Backspace
  9, // Tab
  13, // Enter
  37, // Left
  38, // Up
  39, // Right
  40 // Down
]

// none of react-select's styles are passed to <Control />
export const selectCustomStyle = (style = {}) => ({
  control: (_, { selectProps: { width } }) => ({
    width: width,
    display: 'flex',
    border: '1px solid #cccccc',
    borderRadius: 4,
    height: 'calc(1.5em + 0.75rem + 2px)',
    fontSize: '1rem',
    ...style
  })
})

export const get_PRE_ACCOUNTS = (pre_accounts) => {
  const PRE_ACCOUNTS = {}
  if (pre_accounts) {
    for (const acc of pre_accounts) {
      if (acc.name.toLowerCase() === '__cash__') {
        PRE_ACCOUNTS.__cash__ = acc.id
      } else if (acc.name.toLowerCase() === '__purchases__') {
        PRE_ACCOUNTS.__purchases__ = acc.id
      } else if (acc.name.toLowerCase() === '__sales__') {
        PRE_ACCOUNTS.__sales__ = acc.id
      }
    }
  }
  return PRE_ACCOUNTS
}

export const getAccountsName = (name) => {
  switch (name.toLowerCase()) {
    case '__cash__':
      return 'Cash'
    case '__purchases__':
      return 'Purchase'
    case '__sales__':
      return 'Sales'
    default:
      return name
  }
}
export const getSrNo = (page, perpage, i) => page * perpage - perpage + i + 1
export const constants = {
  accountsPageLength: 20,
  transactionsPageLength: 20,
  invoicesPageLength: 20,
  gstPageLength: 10
}
export const colors = {
  white: '#fff',
  'gray-base': '#181b1e',
  'gray-100': '#ebedef',
  'gray-200': '#d8dbe0',
  'gray-300': '#c4c9d0',
  'gray-400': '#b1b7c1',
  'gray-500': '#9da5b1',
  'gray-600': '#8a93a2',
  'gray-700': '#768192',
  'gray-800': '#636f83',
  'gray-900': '#4f5d73',
  black: '#000'
}

export const usePrevious = (value) => {
  const ref = useRef()
  useEffect(() => {
    ref.current = value
  }, [value])
  return ref.current
}

export const getTrType = (row, acc_id) => {
  const {
    app: {
      currentCompany: { pre_accounts }
    }
  } = store.getState()

  const PRE_ACCOUNTS = get_PRE_ACCOUNTS(pre_accounts)

  if (row.db_account.id === PRE_ACCOUNTS.__cash__ && row.cr_account.id === PRE_ACCOUNTS.__sales__) {
    // Cash sales
    if (acc_id) {
      return {
        tr_type: 'Sales',
        name: acc_id === row.db_account.id ? getAccountsName(row.cr_account.name) : getAccountsName(row.db_account.name),
        db_amount: acc_id === row.db_account.id ? row.amount : null,
        cr_amount: acc_id === row.cr_account.id ? row.amount : null
      }
    }
    return { tr_type: 'Sales', id: row.db_account.id, name: getAccountsName(row.db_account.name), db_amount: row.amount, cr_amount: null }
  }
  if (row.db_account.id === PRE_ACCOUNTS.__purchases__ && row.cr_account.id === PRE_ACCOUNTS.__cash__) {
    // Cash purchase
    if (acc_id) {
      return {
        tr_type: 'Purchase',
        name: acc_id === row.db_account.id ? getAccountsName(row.cr_account.name) : getAccountsName(row.db_account.name),
        db_amount: acc_id === row.db_account.id ? row.amount : null,
        cr_amount: acc_id === row.cr_account.id ? row.amount : null
      }
    }
    return { tr_type: 'Purchase', id: row.cr_account.id, name: getAccountsName(row.cr_account.name), db_amount: null, cr_amount: row.amount }
  }
  if (row.db_account.is_bank && row.cr_account.id === PRE_ACCOUNTS.__sales__) {
    // Bank Sales
    if (acc_id) {
      return {
        tr_type: 'Sales',
        name: acc_id === row.db_account.id ? getAccountsName(row.cr_account.name) : getAccountsName(row.db_account.name),
        db_amount: acc_id === row.db_account.id ? row.amount : null,
        cr_amount: acc_id === row.cr_account.id ? row.amount : null
      }
    }
    return { tr_type: 'Sales', id: row.db_account.id, name: getAccountsName(row.db_account.name), db_amount: row.amount, cr_amount: null }
  }
  if (row.db_account.id === PRE_ACCOUNTS.__purchases__ && row.cr_account.is_bank) {
    // Bank Purchase
    if (acc_id) {
      return {
        tr_type: 'Purchase',
        name: acc_id === row.db_account.id ? getAccountsName(row.cr_account.name) : getAccountsName(row.db_account.name),
        db_amount: acc_id === row.db_account.id ? row.amount : null,
        cr_amount: acc_id === row.cr_account.id ? row.amount : null
      }
    }
    return { tr_type: 'Purchase', id: row.cr_account.id, name: getAccountsName(row.cr_account.name), db_amount: null, cr_amount: row.amount }
  }
  if (
    !row.db_account.is_bank &&
    row.db_account.id !== PRE_ACCOUNTS.__cash__ &&
    row.db_account.id !== PRE_ACCOUNTS.__sales__ &&
    row.db_account.id !== PRE_ACCOUNTS.__purchases__ &&
    row.cr_account.id === PRE_ACCOUNTS.__sales__
  ) {
    // Debit Sales
    if (acc_id) {
      return {
        tr_type: 'Sales',
        name: acc_id === row.db_account.id ? getAccountsName(row.cr_account.name) : getAccountsName(row.db_account.name),
        db_amount: acc_id === row.db_account.id ? row.amount : null,
        cr_amount: acc_id === row.cr_account.id ? row.amount : null
      }
    }
    return { tr_type: 'Sales', id: row.db_account.id, name: getAccountsName(row.db_account.name), db_amount: row.amount, cr_amount: null }
  }
  if (
    row.db_account.id === PRE_ACCOUNTS.__purchases__ &&
    !row.cr_account.is_bank &&
    row.cr_account.id !== PRE_ACCOUNTS.__cash__ &&
    row.cr_account.id !== PRE_ACCOUNTS.__sales__ &&
    row.cr_account.id !== PRE_ACCOUNTS.__purchases__
  ) {
    // Debit Purchase
    if (acc_id) {
      return {
        tr_type: 'Purchase',
        name: acc_id === row.db_account.id ? getAccountsName(row.cr_account.name) : getAccountsName(row.db_account.name),
        db_amount: acc_id === row.db_account.id ? row.amount : null,
        cr_amount: acc_id === row.cr_account.id ? row.amount : null
      }
    }
    return { tr_type: 'Purchase', id: row.cr_account.id, name: getAccountsName(row.cr_account.name), db_amount: null, cr_amount: row.amount }
  }
  if (
    !row.db_account.is_bank &&
    row.db_account.id !== PRE_ACCOUNTS.__cash__ &&
    row.db_account.id !== PRE_ACCOUNTS.__sales__ &&
    row.db_account.id !== PRE_ACCOUNTS.__purchases__ &&
    row.cr_account.id === PRE_ACCOUNTS.__cash__
  ) {
    // Cash Payment
    if (acc_id) {
      return {
        tr_type: 'Payment',
        name: acc_id === row.db_account.id ? getAccountsName(row.cr_account.name) : getAccountsName(row.db_account.name),
        db_amount: acc_id === row.db_account.id ? row.amount : null,
        cr_amount: acc_id === row.cr_account.id ? row.amount : null
      }
    }
    return { tr_type: 'Payment', cr_db: { label: 'Paid', value: 'paid' }, id: row.db_account.id, name: getAccountsName(row.db_account.name), db_amount: row.amount, cr_amount: null }
  }
  if (
    row.db_account.id === PRE_ACCOUNTS.__cash__ &&
    !row.cr_account.is_bank &&
    row.cr_account.id !== PRE_ACCOUNTS.__cash__ &&
    row.cr_account.id !== PRE_ACCOUNTS.__sales__ &&
    row.cr_account.id !== PRE_ACCOUNTS.__purchases__
  ) {
    // Cash Receipt
    if (acc_id) {
      return {
        tr_type: 'Receipt',
        name: acc_id === row.db_account.id ? getAccountsName(row.cr_account.name) : getAccountsName(row.db_account.name),
        db_amount: acc_id === row.db_account.id ? row.amount : null,
        cr_amount: acc_id === row.cr_account.id ? row.amount : null
      }
    }
    return { tr_type: 'Receipt', cr_db: { label: 'Received', value: 'received' }, id: row.cr_account.id, name: getAccountsName(row.cr_account.name), db_amount: null, cr_amount: row.amount }
  }
  if (
    !row.db_account.is_bank &&
    row.db_account.id !== PRE_ACCOUNTS.__cash__ &&
    row.db_account.id !== PRE_ACCOUNTS.__sales__ &&
    row.db_account.id !== PRE_ACCOUNTS.__purchases__ &&
    row.cr_account.is_bank
  ) {
    // Bank Payment
    if (acc_id) {
      return {
        tr_type: 'Payment',
        name: acc_id === row.db_account.id ? getAccountsName(row.cr_account.name) : getAccountsName(row.db_account.name),
        db_amount: acc_id === row.db_account.id ? row.amount : null,
        cr_amount: acc_id === row.cr_account.id ? row.amount : null
      }
    }
    return { tr_type: 'Payment', cr_db: { label: 'Paid', value: 'paid' }, id: row.db_account.id, name: getAccountsName(row.db_account.name), db_amount: row.amount, cr_amount: null }
  }
  if (
    row.db_account.is_bank &&
    !row.cr_account.is_bank &&
    row.cr_account.id !== PRE_ACCOUNTS.__cash__ &&
    row.cr_account.id !== PRE_ACCOUNTS.__sales__ &&
    row.cr_account.id !== PRE_ACCOUNTS.__purchases__
  ) {
    // Bank Receipt
    if (acc_id) {
      return {
        tr_type: 'Receipt',
        name: acc_id === row.db_account.id ? getAccountsName(row.cr_account.name) : getAccountsName(row.db_account.name),
        db_amount: acc_id === row.db_account.id ? row.amount : null,
        cr_amount: acc_id === row.cr_account.id ? row.amount : null
      }
    }
    return { tr_type: 'Receipt', cr_db: { label: 'Received', value: 'received' }, id: row.cr_account.id, name: getAccountsName(row.cr_account.name), db_amount: null, cr_amount: row.amount }
  }
  if (row.cr_account.is_bank && row.db_account.id === PRE_ACCOUNTS.__cash__) {
    if (acc_id) {
      return {
        tr_type: 'Withdraw',
        name: acc_id === row.db_account.id ? getAccountsName(row.cr_account.name) : getAccountsName(row.db_account.name),
        db_amount: acc_id === row.db_account.id ? row.amount : null,
        cr_amount: acc_id === row.cr_account.id ? row.amount : null
      }
    }
    return { tr_type: 'Withdraw', cr_db: { label: 'Received', value: 'received' }, id: row.cr_account.id, name: getAccountsName(row.cr_account.name), db_amount: null, cr_amount: row.amount }
  }
  if (row.db_account.is_bank && row.cr_account.id === PRE_ACCOUNTS.__cash__) {
    if (acc_id) {
      return {
        tr_type: 'Deposite',
        name: acc_id === row.db_account.id ? getAccountsName(row.cr_account.name) : getAccountsName(row.db_account.name),
        db_amount: acc_id === row.db_account.id ? row.amount : null,
        cr_amount: acc_id === row.cr_account.id ? row.amount : null
      }
    }
    return { tr_type: 'Deposite', cr_db: { label: 'Paid', value: 'paid' }, id: row.db_account.id, name: getAccountsName(row.db_account.name), db_amount: row.amount, cr_amount: null }
  }
}

/**
cash sales: cash db / sales cr
cash purchase: purchase db / cash cr

bank sales: bank db / sales cr
bank purchase: purchase db / bank cr

debit sales: account db / sales cr
debit purchase: purchase db / account cr

cash paid to: account db / cash cr
cash received: cash db / account cr

bank paid to: account db / bank cr
bank received: bank db / account cr
*/

export const price_in_words = (price) => {
  var sglDigit = ['Zero', 'One', 'Two', 'Three', 'Four', 'Five', 'Six', 'Seven', 'Eight', 'Nine'],
    dblDigit = ['Ten', 'Eleven', 'Twelve', 'Thirteen', 'Fourteen', 'Fifteen', 'Sixteen', 'Seventeen', 'Eighteen', 'Nineteen'],
    tensPlace = ['', 'Ten', 'Twenty', 'Thirty', 'Forty', 'Fifty', 'Sixty', 'Seventy', 'Eighty', 'Ninety'],
    handle_tens = function (dgt, prevDgt) {
      return 0 === dgt ? '' : ' ' + (1 === dgt ? dblDigit[prevDgt] : tensPlace[dgt])
    },
    handle_utlc = function (dgt, nxtDgt, denom) {
      return (0 !== dgt && 1 !== nxtDgt ? ' ' + sglDigit[dgt] : '') + (0 !== nxtDgt || dgt > 0 ? ' ' + denom : '')
    }

  var str = '',
    digitIdx = 0,
    digit = 0,
    nxtDigit = 0,
    words = []
  if ((price += '') && isNaN(parseInt(price))) str = ''
  else if (parseInt(price) > 0 && price.length <= 10) {
    for (digitIdx = price.length - 1; digitIdx >= 0; digitIdx--)
      switch ((digit = price[digitIdx] - 0) && (nxtDigit = digitIdx > 0 ? price[digitIdx - 1] - 0 : 0) && price.length - digitIdx - 1) {
        case 0:
          words.push(handle_utlc(digit, nxtDigit, ''))
          break
        case 1:
          words.push(handle_tens(digit, price[digitIdx + 1]))
          break
        case 2:
          words.push(0 !== digit ? ' ' + sglDigit[digit] + ' Hundred' + (0 !== price[digitIdx + 1] && 0 !== price[digitIdx + 2] ? ' and' : '') : '')
          break
        case 3:
          words.push(handle_utlc(digit, nxtDigit, 'Thousand'))
          break
        case 4:
          words.push(handle_tens(digit, price[digitIdx + 1]))
          break
        case 5:
          words.push(handle_utlc(digit, nxtDigit, 'Lakh'))
          break
        case 6:
          words.push(handle_tens(digit, price[digitIdx + 1]))
          break
        case 7:
          words.push(handle_utlc(digit, nxtDigit, 'Crore'))
          break
        case 8:
          words.push(handle_tens(digit, price[digitIdx + 1]))
          break
        case 9:
          words.push(0 !== digit ? ' ' + sglDigit[digit] + ' Hundred' + (0 !== price[digitIdx + 1] || 0 !== price[digitIdx + 2] ? ' and' : ' Crore') : '')
          break
        default:
          break
      }
    str = words.reverse().join('')
  } else str = ''
  return str
}

export const toCurrency = (amount) => {
  amount = amount.toLocaleString('en-IN', {
    maximumFactionDigits: 2
  })
  return amount
}
