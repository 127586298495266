import { isEmpty } from 'lodash'
import apiService from 'utils/apiService'
import { convertObjectToQuerystring } from 'utils/helper'
import { getUserToken } from 'utils/storageUtils'

export function getTransactions_api(filters) {
  let token = getUserToken()
  let filter = ''
  if (!isEmpty(filters)) {
    filter = `&${convertObjectToQuerystring(filters)}`
  }
  return apiService(`transactions?${filter}`, null, 'get', token)
}
export function createTransactions_api(data) {
  let token = getUserToken()
  return apiService(`transactions`, data, 'post', token)
}
export function deleteTransactions_api(unique_id) {
  let token = getUserToken()
  return apiService(`transactions/${unique_id}`, null, 'delete', token)
}