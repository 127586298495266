import { isEmpty } from 'lodash'
import apiService from 'utils/apiService'
import { convertObjectToQuerystring } from 'utils/helper'
import { getUserToken } from 'utils/storageUtils'

export function getCategories_api(filters) {
  let token = getUserToken()
  let filter = ''
  if (!isEmpty(filters)) {
    filter = `&${convertObjectToQuerystring(filters)}`
  }
  return apiService(`categories?${filter}`, null, 'get', token)
}
export function createCategories_api(data) {
  let token = getUserToken()
  if (!data.id) {
    return apiService(`categories`, data, 'post', token)
  } else {
    data._method = 'put'
    return apiService(`categories/${data.id}`, data, 'post', token)
  }
}
export function deleteCategories_api(id) {
  let token = getUserToken()
  return apiService(`categories/${id}`, null, 'delete', token)
}
