import React, { Component } from 'react'
import { Route, Switch } from 'react-router-dom'
import { connect } from 'react-redux'
import './App.scss'
import * as userActions from 'modules/auth/actions'
import Toast from 'components/Toast'
import { getCategories_action } from 'modules/Categories/actions'
import { getProducts_action } from 'modules/Products/actions'
const DefaultLayout = React.lazy(() => import('./DefaultLayout'))
const Login = React.lazy(() => import('./modules/auth/Login.js'))
const loading = () => <div className='animated fadeIn pt-3 text-center'>Loading...</div>
class App extends Component {
  componentDidMount() {
    this.props.restoreSession()
  }
  componentDidUpdate(prevProps) {
    if (this.props.auth.token) {
      if (this.props.app.currentCompany.id && prevProps.app.currentCompany.id !== this.props.app.currentCompany.id) {
        this.props.getCategories({ company_id: this.props.app.currentCompany.id })
        this.props.getProducts({ company_id: this.props.app.currentCompany.id, sortBy: 'name,asc' })
      }
    }
  }
  render() {
    return (
      <React.Fragment>
        <Toast />
        <React.Suspense fallback={loading()}>
          <Switch>
            <Route exact path='/login' name='Login Page' render={(props) => <Login {...props} />} />
            <Route path='/' name='Home' render={(props) => <DefaultLayout {...props} />} />
          </Switch>
        </React.Suspense>
      </React.Fragment>
    )
  }
}

const mapStateToProps = ({ auth, app }) => ({
  auth,
  app
})

const mapActionsToProps = (dispatch) => ({
  restoreSession: () => dispatch(userActions.restoreSession()),
  getCategories: (filters) => dispatch(getCategories_action(filters)),
  getProducts: (filters) => new Promise((resolve, reject) => dispatch(getProducts_action(filters, resolve, reject)))
})

export default connect(mapStateToProps, mapActionsToProps)(App)
