import * as types from './types'

export const getTransaction_action = (filters, resolve, reject) => ({
  type: types.GET_TRANSACTIONS,
  filters,
  resolve,
  reject
})
export const setTransaction_action = ({ data, stats }) => ({
  type: types.SET_TRANSACTIONS,
  data,
  stats
})

export const createTransaction_action = (data, resolve, reject) => ({
  type: types.CREATE_TRANSACTION,
  data,
  resolve,
  reject
})
export const deleteTransaction_action = (unique_id, resolve, reject) => ({
  type: types.DELETE_TRANSACTION,
  unique_id,
  resolve,
  reject
})

export const setInvoices_action = ({ data, stats }) => ({
  type: types.SET_INVOICES,
  data,
  stats
})