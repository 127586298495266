import { all, call, fork, put, takeEvery } from 'redux-saga/effects'
import * as types from './types'
import * as apis from './apis'
import { setGSTLogPurchase_action, setGSTLogSales_action } from './actions'

function* getGSTLogsSaga({ filters, resolve, reject }) {
  try {
    const response = yield call(apis.getGSTLogs_api, filters)
    if (filters.invoice_type === 'sales') {
      yield put(setGSTLogSales_action(response))
    } else if (filters.invoice_type === 'purchase') {
      yield put(setGSTLogPurchase_action(response))
    }
    resolve(response)
  } catch (error) {
    reject(error)
  }
}
export function* watchSagas() {
  yield takeEvery(types.GET_GSTLOGS, getGSTLogsSaga)
}
export default function* transactionsSagas() {
  yield all([fork(watchSagas)])
}
