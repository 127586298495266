import { all, call, fork, put, takeLeading } from 'redux-saga/effects'
import * as types from './types'
import * as apis from './apis'
import { setAccounts_action } from './actions';

function* getAccountsSaga({ filters, resolve, reject }) {
  try {
    const response = yield call(apis.getAccounts_api, filters)
    yield put(setAccounts_action(response))
    resolve && resolve(response)
  } catch (error) {
    reject && reject(error)
  }
}
function* createAccountsSaga({ data, resolve, reject }) {
  try {
    const response = yield call(apis.createAccounts_api, data)
    resolve(response)
  } catch (error) {
    reject(error)
  }
}

function* deleteAccountsSaga({ id, resolve, reject }) {
  try {
    yield call(apis.deleteAccounts_api, id)
    resolve()
  } catch (error) {
    reject(error)
  }
}

export function* watchSagas() {
  yield takeLeading(types.GET_ACCOUNTS, getAccountsSaga)
  yield takeLeading(types.CREATE_ACCOUNT, createAccountsSaga)
  yield takeLeading(types.DELETE_ACCOUNT, deleteAccountsSaga)
}
export default function* accountSagas() {
  yield all([fork(watchSagas)])
}
