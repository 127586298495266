import * as types from './types'

export const getCategories_action = (filters, resolve, reject) => ({
  type: types.GET_CATEGORIES,
  filters,
  resolve,
  reject
})
export const setCategories_action = (data) => ({
  type: types.SET_CATEGORIES,
  data
})

export const createCategory_action = (data, resolve, reject) => ({
  type: types.CREATE_CATEGORY,
  data,
  resolve,
  reject
})
export const deleteCategory_action = (id, resolve, reject) => ({
  type: types.DELETE_CATEGORY,
  id,
  resolve,
  reject
})
