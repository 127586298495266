import * as types from './types'

export const getAccounts_action = (filters, resolve, reject) => ({
  type: types.GET_ACCOUNTS,
  filters,
  resolve,
  reject
})
export const setAccounts_action = ({ data, stats }) => ({
  type: types.SET_ACCOUNTS,
  data,
  stats
})

export const createAccount_action = (data, resolve, reject) => ({
  type: types.CREATE_ACCOUNT,
  data,
  resolve,
  reject
})
export const deleteAccount_action = (id, resolve, reject) => ({
  type: types.DELETE_ACCOUNT,
  id,
  resolve,
  reject
})
