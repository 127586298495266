import * as types from './types'

export const getCompanies_action = () => ({
  type: types.GET_COMPANIES
})
export const setCompanies_action = (data, financial_years) => ({
  type: types.SET_COMPANIES,
  data,
  financial_years
})
export const setCurrentCompany_action = (data) => ({
  type: types.SET_CURRENT_COMPANY,
  data
})
export const setCurrentDate_action = (data) => ({
  type: types.SET_CURRENT_DATE,
  data
})
