import apiService from 'utils/apiService'
import { getUserToken } from 'utils/storageUtils'

export const getCompanies_api = () => {
  const token = getUserToken()
  return apiService(`companies`, null, 'get', token)
}

export const getBankOutstanding_api = (company_id) => {
  const token = getUserToken()
  return apiService(`bankoutstandings?company_id=${company_id}`, null, 'get', token)
}
export const updateFincancialYear_api = (fy_id) => {
  const token = getUserToken()
  return apiService(`financial_years/${fy_id}`, { _method: 'patch' }, 'post', token)
}
