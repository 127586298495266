import * as types from 'modules/auth/types'

export const loginUser = ({ data, resolve, reject }) => ({
  type: types.LOGIN_USER,
  data,
  resolve,
  reject
})

export const logoutUser = () => ({
  type: types.LOGOUT_USER
})

export const restoreSession = (token) => ({
  type: types.RESTORE_SESSION,
  token
})
export const setLoggedInUser = (data) => ({
  type: types.SET_LOGGED_IN_USER,
  data
})
export const setLoggedOutUser = (data) => ({
  type: types.SET_USER_LOGOUT,
  data
})

export const resetPassword = (data) => ({
  type: types.RESET_PASSWORD,
  data
})
