// Set
export const setUserData = (data) => {
  localStorage.setItem('userData', JSON.stringify(data))
}
export const setUserToken = (token) => {
  localStorage.setItem('token', `Bearer ${token}`)
}
export const setUserLoginData = (data) => {
  localStorage.setItem('userLoginData', JSON.stringify(data))
}
export const setCurrentCompany = (data) => {
  localStorage.setItem('currentCompany', JSON.stringify(data))
}

// Get
export const getUserToken = () => {
  return localStorage.getItem('token')
}
export const getUserData = () => {
  return JSON.parse(localStorage.getItem('userData'))
}
export const getUserLoginData = () => {
  return JSON.parse(localStorage.getItem('userLoginData'))
}
export const getCurrentCompany = () => {
  return JSON.parse(localStorage.getItem('currentCompany'))
}

// Unset
export const unsetUserData = () => {
  localStorage.removeItem('userData')
}
export const unsetUserToken = () => {
  localStorage.removeItem('token')
}
export const unsetUserLoginData = () => {
  localStorage.removeItem('userLoginData')
}
export const unsetCurrentCompany = () => {
  localStorage.removeItem('currentCompany')
}
