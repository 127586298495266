import * as types from './types'

export const getGSTLog_action = (filters, resolve, reject) => ({
  type: types.GET_GSTLOGS,
  filters,
  resolve,
  reject
})
export const setGSTLogSales_action = ({ data, stats }) => ({
  type: types.SET_GSTLOG_SALES,
  data,
  stats
})
export const setGSTLogPurchase_action = ({ data, stats }) => ({
  type: types.SET_GSTLOG_PURCHASE,
  data,
  stats
})
