import * as types from './types'

const initialState = {
  list: [],
  stats: {}
}
const reducer = (state = initialState, action) => {
  switch (action.type) {
    case types.SET_ACCOUNTS: {
      return {
        ...state,
        list: action.data,
        stats: action.stats
      }
    }
    default:
      return state
  }
}
export default reducer
