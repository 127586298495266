import apiService from 'utils/apiService'
import { getUserToken } from 'utils/storageUtils'

export function authLogin(data) {
  data.uuid = process.env.REACT_APP_ADMIN_UUID
  return apiService(`auth/login`, data, 'post', '')
}

export function authConfirm() {
  const token = getUserToken()
  return apiService(`user/info`, null, 'get', token)
}

export function resetPassword(data) {
  return apiService(`auth/resetPassword`, data, 'post', '')
}

export function changePassword(data) {
  const token = getUserToken()
  return apiService(`auth/changePassword`, data, 'post', token)
}

export function logout() {
  const token = getUserToken()
  return apiService('auth/logout', null, 'post', token)
}
