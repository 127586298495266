import { history } from 'store/store'

export default function apiService(path, params, method, token, isFormData) {
  let options
  options = {
    headers:
      isFormData === true
        ? {
            Accept: 'application/json',
            ...(token && { Authorization: token })
          }
        : {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            ...(token && { Authorization: token })
          },
    method: method,
    ...(params && { body: isFormData ? params : JSON.stringify(params) })
  }

  return new Promise((resolve, reject) => {
    fetch(`${process.env.REACT_APP_API_URL}${path}`, options)
      .then((response) => {
        return new Promise((resolve) =>
          response.json().then((json) =>
            resolve({
              status: response.status,
              ok: response.ok,
              json
            })
          )
        )
      })
      .then((response) => {
        if (response.ok) {
          return resolve(response.json)
        } else if (response.status === 401 && !history.location.pathname.includes('/login')) {
          history.replace('/login')
        } else {
          return reject(response.json)
        }
      })
      .catch((error) =>
        reject({
          networkError: error.message
        })
      )
  })
}
