import moment from 'moment'
import { setCurrentCompany } from 'utils/storageUtils'
import * as types from './types'

const initialState = {
  companies: [],
  financial_years: [],
  currentCompany: {},
  currentDate: new Date()
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case types.SET_COMPANIES: {
      const financial_years = action.financial_years.map((y) => ({ ...y, display: `${moment(y.year_starts).format('yyyy')}-${moment(y.year_ends).format('yyyy')}` }))
      return {
        ...state,
        companies: action.data,
        financial_years
      }
    }
    case types.SET_CURRENT_COMPANY: {
      setCurrentCompany(action.data)
      return {
        ...state,
        currentCompany: action.data
      }
    }
    case types.SET_CURRENT_DATE: {
      return {
        ...state,
        currentDate: action.data
      }
    }
    default:
      return state
  }
}
export default reducer
