import * as types from './types'

const initialState = {
  isLoggedIn: false,
  user: null,
  token: null,
  isLoading: true,
  userProfileImage: [],
  userEmail: null,
  isCodeSent: false
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case types.SET_LOGGED_IN_USER:
      return {
        ...state,
        user: action.data.user,
        isLoggedIn: true,
        token: 'Bearer ' + action.data.token
      }

    case types.SET_USER_LOGOUT:
      return {
        ...state,
        user: null,
        isLoggedIn: false,
        token: null
      }
    default:
      return state
  }
}
export default reducer
