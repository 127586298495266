import * as types from './types'
import { all, call, fork, put, takeLeading } from 'redux-saga/effects'
import { getCompanies_api } from './apis'
import { setCompanies_action, setCurrentCompany_action } from './actions'
import { getCurrentCompany, setCurrentCompany } from 'utils/storageUtils'

function* getCompanies_saga() {
  try {
    const result = yield call(getCompanies_api)
    yield put(setCompanies_action(result.data, result.financial_years))
    // get/set current company
    const currentCompany = getCurrentCompany()
    if (!currentCompany) {
      yield call(setCurrentCompany, result.data[0])
      yield put(setCurrentCompany_action(result.data[0]))
    } else {
      yield put(setCurrentCompany_action(currentCompany))
    }
  } catch (error) {
    console.log(error)
  }
  yield ''
}
function* watchCompaniesSagas() {
  yield takeLeading(types.GET_COMPANIES, getCompanies_saga)
}
export default function* companiesSaga() {
  yield all([fork(watchCompaniesSagas)])
}
