import * as types from './types'

const initialState = {
  sales: {
    list: [],
    stats: {}
  },
  purchase: {
    list: [],
    stats: {}
  }
}
const reducer = (state = initialState, action) => {
  switch (action.type) {
    case types.SET_GSTLOG_SALES: {
      return {
        ...state,
        sales: { list: action.data, stats: action.stats }
      }
    }
    case types.SET_GSTLOG_PURCHASE: {
      return {
        ...state,
        purchase: { list: action.data, stats: action.stats }
      }
    }
    default:
      return state
  }
}
export default reducer
