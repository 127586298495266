import * as types from './types'

const initialState = {
  transactions: {
    list: [],
    stats: {}
  },
  invoices: {
    list: [],
    stats: {}
  }
}
const reducer = (state = initialState, action) => {
  switch (action.type) {
    case types.SET_TRANSACTIONS: {
      return {
        ...state,
        transactions: { list: action.data, stats: { ...action.stats } }
      }
    }
    case types.SET_INVOICES: {
      return {
        ...state,
        invoices: { list: action.data, stats: action.stats }
      }
    }
    default:
      return state
  }
}

export default reducer
