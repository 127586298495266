import { all, call, fork, put, takeLeading } from 'redux-saga/effects'
import * as types from './types'
import * as apis from './apis'
import { setProducts_action } from './actions'

function* getProducts_saga({ filters, resolve, reject }) {
  try {
    const response = yield call(apis.getProducts_api, filters)
    yield put(setProducts_action(response))
    if (resolve) resolve()
  } catch (error) {
    if (reject) reject(error)
  }
}

function* createProduct_saga({ data, resolve, reject }) {
  try {
    yield call(apis.createProduct_api, data)
    if (resolve) resolve()
  } catch (error) {
    if (reject) reject(error)
  }
}

function* updateProduct_saga({ data, resolve, reject }) {
  try {
    yield call(apis.updateProduct_api, data)
    if (resolve) resolve()
  } catch (error) {
    if (reject) reject(error)
  }
}

function* deleteProduct_saga({ id, resolve, reject }) {
  try {
    yield call(apis.deleteProduct_api, id)
    if (resolve) resolve()
  } catch (error) {
    if (reject) reject(error)
  }
}

export function* watchSagas() {
  yield takeLeading(types.GET_PRODUCTS, getProducts_saga)
  yield takeLeading(types.CREATE_PRODUCT, createProduct_saga)
  yield takeLeading(types.UPDATE_PRODUCT, updateProduct_saga)
  yield takeLeading(types.DELETE_PRODUCT, deleteProduct_saga)
}

export default function* sagas() {
  yield all([fork(watchSagas)])
}
