import { all } from 'redux-saga/effects'
import authSaga from 'modules/auth/sagas'
import companiesSaga from 'modules/App/sagas'
import accountSaga from 'modules/Accounts/sagas'
import transactionSaga from 'modules/Transactions/sagas'
import gstSaga from 'modules/GST/sagas'
import categorySaga from 'modules/Categories/sagas'
import productsSaga from 'modules/Products/sagas'

export default function* rootSaga() {
  yield all([authSaga(), companiesSaga(), accountSaga(), transactionSaga(), gstSaga(), categorySaga(), productsSaga()])
}
