import { all, call, fork, put, takeEvery, takeLeading, select, delay } from 'redux-saga/effects'
import * as types from './types'
import * as userActions from './actions'
import * as authApi from './apis'
import * as storageUtils from 'utils/storageUtils'
import { replace, push } from 'connected-react-router'
import toast from 'utils/toast'
import { cloneDeep } from 'lodash'
import { history } from 'store/store'

export const getCurrentRoute = (state) => state.router.location.pathname

function* setAuthentication(data) {
  const user = cloneDeep(data)
  delete user.token
  const userData = {
    token: data.token,
    user
  }
  yield put(userActions.setLoggedInUser(userData))
  yield call(storageUtils.setUserToken, data.token)
  yield call(storageUtils.setUserData, user)
  delay(1000)
  window.location.href = '/'
}

function* loginUser({ data, resolve, reject }) {
  let response
  try {
    const { email, password } = data
    response = yield call(authApi.authLogin, { email, password })
    yield setAuthentication(response)
    resolve()
  } catch (error) {
    const errors = error._errors.reduce((prevVal, newVal) => `${prevVal}<br/>${newVal}`)
    reject(errors)
  }
}

function* logoutUser() {
  try {
    yield call(authApi.logout)
  } catch (err) {
    console.log(err)
  }
  yield call(storageUtils.unsetUserData)
  yield call(storageUtils.unsetUserToken)
  yield call(storageUtils.unsetCurrentCompany)
  yield put(userActions.setLoggedOutUser())
  if (!history.location.pathname.includes('/login')) {
    yield put(replace('/login'))
  }
}

function* restoreSession() {
  let token = yield call(storageUtils.getUserToken)
  let user = yield call(storageUtils.getUserData)
  if (token && token !== '') {
    try {
      const userData = {
        token: token,
        user: user
      }
      yield put(userActions.setLoggedInUser(userData))
    } catch (error) {
      yield put(push('/login'))
    }
  } else {
    const pathname = yield select(getCurrentRoute)
    if (!pathname.includes('login')) {
      yield put(push('/login'))
    }
  }
}

function* resetPassword(action) {
  let data
  try {
    data = yield call(authApi.resetPassword, action.data)
    toast.success(data.message)
    // console.log('resetPassword', data);
    yield put(push('/login'))
  } catch (err) {
    err.then((response) => {
      response._errors.forEach((message) => {
        toast.error({ message })
      })
    })
  }
}

export function* watchLoginUser() {
  yield takeEvery(types.LOGIN_USER, loginUser)
  yield takeEvery(types.LOGOUT_USER, logoutUser)
  yield takeEvery(types.RESTORE_SESSION, restoreSession)
  yield takeLeading(types.RESET_PASSWORD, resetPassword)
}

export default function* authSaga() {
  yield all([fork(watchLoginUser)])
}
