import { all, call, fork, put, select, takeLeading } from 'redux-saga/effects'
import * as types from './types'
import * as apis from './apis'
import { setCategories_action } from './actions'

function* getCategoriesSaga({ filters, resolve, reject }) {
  try {
    const response = yield call(apis.getCategories_api, filters)
    yield put(setCategories_action(response))
    if (resolve) resolve()
  } catch (error) {
    if (reject) reject()
  }
}

function* createCategoriesSaga({ data, resolve, reject }) {
  try {
    const response = yield call(apis.createCategories_api, data)
    const categories = yield select((state) => state.categories.list)
    yield put(setCategories_action([...categories, response]))
    resolve(response)
  } catch (error) {
    reject(error)
  }
}

function* deleteAccountsSaga({ id, resolve, reject }) {
  try {
    yield call(apis.deleteCategories_api, id)
    resolve()
  } catch (error) {
    reject(error)
  }
}

export function* watchSagas() {
  yield takeLeading(types.GET_CATEGORIES, getCategoriesSaga)
  yield takeLeading(types.CREATE_CATEGORY, createCategoriesSaga)
  yield takeLeading(types.DELETE_CATEGORY, deleteAccountsSaga)
}

export default function* sagas() {
  yield all([fork(watchSagas)])
}
